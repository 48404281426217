import logo from './logo.svg';
import './App.css';
import dates2023 from "./2023.json"
import dates2022 from "./2022.json"
import dates2020 from "./2020.json"
import dates2019 from "./2019.json"

import wwwImage from "./assets/www.svg"
import fbImage from "./assets/fb.svg"
import instaImage from "./assets/insta.svg"
import ytImage from "./assets/yt.svg"
import twittImage from "./assets/twitt.svg"
import Header from "./Header"
import Footer from "./Footer"

import React, { useEffect, useState } from 'react'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { Container, Row, Col } from 'react-bootstrap';
import Search from 'react-search'
import Multiselect from 'multiselect-react-dropdown';


const years = [
  2023, 2022, 2020, 2019
]
function RenderElement(props) {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let rowBg
  if (props.index % 2 != 0) {
    rowBg = "#F9F9F9"
  }
  else {
    rowBg = "#FFFFFF"
  }
  let dates = props.date.Date.split(" ");
  let finalString = ''
  if (dates.length == 1) {
    var parts = dates[0].split(".");
    let date = new Date(parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10));

    finalString = date.getDate() + " " + monthNames[date.getMonth()]
  }
  else {
    var parts1 = dates[0].split(".");

    var parts2 = dates[1].split(".");

    let date1 = new Date(parseInt(parts1[2], 10),
      parseInt(parts1[1], 10) - 1,
      parseInt(parts1[0], 10));
    let date2 = new Date(parseInt(parts2[2], 10),
      parseInt(parts2[1], 10) - 1,
      parseInt(parts2[0], 10));
    finalString = date1.getDate() + " " + monthNames[date1.getMonth()] + " - " + date2.getDate() + " " + monthNames[date2.getMonth()]

  }
  let date = new Date(props.date.Date)

  return (
    <Row id={"day" + props.index} style={{ background: rowBg, padding: "80px 5%" }}>
      <Col md={4} className="py-2">
        <p><span>day</span></p>
        <h2>{props.date.Day}</h2>
      </Col>
      <Col md={2} className="py-2">
        <p><span>date</span></p>
        <h2>{finalString}</h2>
      </Col>

      <Col md={6} className="py-2">
        <p><span>organization</span></p>
        <h2>{props.date.Organization}</h2>
      </Col>
      <Col md={12} style={{ padding: "20px 0" }}>

      </Col>
      <Col md={6} className="py-2" style={{ paddingRight: "50px" }}>
        <p><span>theme</span></p>
        <p>{props.date.Theme}</p>
      </Col>
      <Col md={1} className="py-2">
        <p><span>link</span></p>

        <a href={props.date.Link}><img className="iconMargin" src={wwwImage} target="_blank" /></a>
      </Col>
      <Col md={3} className="py-2">
        <p><span>social media</span></p>

        <p>
          {props.date.Facebook != '' && props.date.Twitter != undefined ? <a href={props.date.Facebook} target="_blank"><img className="iconMargin" src={fbImage} /></a> : <></>}
          {props.date.Instagram != '' && props.date.Twitter != undefined ? <a href={props.date.Instagram} target="_blank"><img className="iconMargin" src={instaImage} /></a> : <></>}
          {props.date.Twitter != '' && props.date.Twitter != undefined ? <a href={props.date.Twitter} target="_blank"><img className="iconMargin" src={twittImage} /></a> : <></>}
          {props.date.Youtube != '' && props.date.Twitter != undefined ? <a href={props.date.Youtube} target="_blank"><img className="iconMargin" src={ytImage} /></a> : <></>}

        </p>
      </Col>
      <Col md={2} className="py-2">
        <p><span>hashtag</span></p>

        <p> {props.date.Hashtag != null ? props.date.Hashtag : <></>}</p>
      </Col>
    </Row>
  )
}

function App() {
  let multiselectRef = React.createRef();

  const [dates, setDates] = useState(dates2023);
  const [datesSearch, setDatesSearch] = useState([{ id: 0, name: "Empty" }]);
  const [ref, setRef] = useState(null);
  const [curr, setCurr] = useState(null)



  const tempRef = React.useCallback(node => {
    if (node !== null) {
      // console.log("canvasRef.new", node); // node = elRef.current
      let tempContext = node

      // console.log("tempContext", tempContext)
      setRef(tempContext);
    }
  }, []);


  const handleYearChange = (e) => {
    console.log("handle", e.target.value)
    switch (e.target.value) {
      case "2019":
        setDates(dates2019)
        break;
      case "2020":
        setDates(dates2020)
        break;
      case "2022":
        setDates(dates2022)
        break;
      case "2023":
        setDates(dates2023)

        break;
    }
  }



  useEffect(() => {
    let data = []
    for (let date of dates) {
      data.push({ id: index, name: date.Day })
      index++;
    }
    setDatesSearch(data)
  }, [dates])

  let index = 0
  useEffect(() => {
    if (ref) ref.resetSelectedValues()

  }, [curr])

  return (
    <div className="App">
      <ThemeProvider >
        <div id="header">
          <Header
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs" />
        </div>
        <div id="toolbar">
          <Container fluid style={{ textAlign: 'left' }}>
            {/* <ReactSearchBox
            placeholder="Search"
            value="Day"
            data={data}
            callback={(record) => console.log(record)}
          /> */}
            <Row style={{
              background: "transparent linear-gradient(90deg, #FBF4DB 0%, #EDDEA5 100%) 0% 0% no-repeat padding-box"
            }}>
              <Col md={4} xl={2} style={{ background: "#10B0BF 0% 0% no-repeat padding-box" }} className="d-flex align-items-center">
                <p style={{
                  display: "inline-block",
                  padding: "0 10px 0 80px",
                  margin: "0 ",
                  marginBottom: "0 "
                }}><span>choose year</span></p>

                <select className="selectYear" onChange={handleYearChange} >
                  {years.map(year => {
                    return <option name={year} id={year}>{year}</option>
                  })
                  }
                </select>
              </Col>
              <Col md={4} xl={6} />

              <Col md={4} >

                {/* <Search items={datesSearch}
                placeholder='Pick your day'
              // maxSelected={1}
              // multiple={false}
              // onItemsChanged={item => {
              //   console.log(item)
              //   if (item.length != 0) document.getElementById("day" + item[0].id).scrollIntoView()
              // }} 
              /> */}
                <Multiselect
                  options={datesSearch} // Options to display in the dropdown
                  // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  // singleSelect={true}
                  closeOnSelect={true}
                  onSelect={async item => {
                    setCurr(item)

                    if (item.length != 0) {
                      document.getElementById("day" + item[0].id).scrollIntoView()
                    }
                  }}
                  ref={tempRef}
                  placeholder="Search"
                  displayValue="name" // Property name to display in the dropdown options
                />

              </Col>

            </Row>
          </Container>
        </div>
        <Container fluid>

          {dates.map((date, index) => (

            <RenderElement key={index} date={date} index={index} />
          ))}
        </Container>

      </ThemeProvider>
      <Footer />
    </div >
  );
}

export default App;
