import logo from './assets/logox2.png';
import bg from './assets/bg.svg';

import './App.css';

import React from 'react'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { Container, Row, Col } from 'react-bootstrap';




function Header() {


    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        ><Container fluid>
                <Row style={{ background: "transparent url('" + bg + "') 0% 0% no-repeat padding-box" }} className="py-5">
                    <Col md={1} />

                    <Col md={3} >
                        <img src={logo} style={{ width: "100%" }} />
                    </Col>
                </Row>
            </Container>
        </ThemeProvider>
    );
}

export default Header;
