import logo from './assets/logobottom.svg';
import platformImg from './assets/platformfooter.png';

import './App.css';

import React from 'react'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { Container, Row, Col } from 'react-bootstrap';




function Header() {


    return (
        <div className="App">
            <ThemeProvider
                breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                minBreakpoint="xxs"
            ><Container fluid>
                    <Row style={{ overflow: "hidden", background: "transparent linear-gradient(270deg, #10B0BF 0%, #73C9D1 100%) 0% 0% no-repeat content-box", paddingTop: "32px", marginTop: "-32px" }} >
                        <Col md={2} xxs={4} />

                        <Col md={2} xxs={4} style={{ textAlign: "center", margin: "-1% 0" }}>
                            <img src={logo} style={{ width: "90%" }} />
                        </Col>
                        <Col md={4} className="d-flex align-items-end justify-content-center ">
                            <h3 className="py-5 "><strong>World Health Record</strong> EVERY DAY MATTERS</h3>
                        </Col>

                        <Col md={{ span: 2, offset: 0 }} xxs={{ span: 4, offset: 4 }} className="d-flex align-items-center">
                            <a href="https://platformcan.com" target="_blank"><img src={platformImg} style={{ width: "100%", marginTop: "25%" }} /></a>
                        </Col>
                    </Row>
                </Container>
            </ThemeProvider>
        </div>
    );
}

export default Header;
